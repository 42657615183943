<template>
    <div>
        <modal
            class="new-modal-small"
            :name="modal_name"
            transition="nice-modal-fade"
            :delay="100"
            height="auto"
            :min-height="200"
            :min-width="400"
            :pivot-y="0.5"
            :adaptive="true"
            :scrollable="true"
        >
            <div class="v-modal-content d-flex flex-column justify-content-center align-items-center text-center" style="background-color: #fff !important; height: 30em !important;">
                <img src="/static/images/delete_pop_icon.svg" width="103px" height="109px" />
                <div class="mt-5">
                    <div class="mb-2 fw-600" style="color:#f30d0d;font-size: 16px;">Delete Business Unit</div>
                    <div>
                        <span class="fs-14 fw-550">Are you Sure you want to delete this<br> business Unit,</span>
                    </div>
                    <div class="text-center">
                        <!-- <span class="fs-14 fw-550">Deleting Business Unit may also delete departments<br> associated with it.</span> -->
                        <!-- <div class="d-flex align-items-center flex-column mt-2">
                            <ul class="p-0">
                                <li class="fs-13 text-left">Departments</li>
                            </ul>
                        </div> -->
                    </div>
                </div>
                <div class="d-flex aligin-items-center justify-content-center mt-6">        
                    <button
                        type="button"
                        class="btn btn-outline-secondary"
                        style="box-shadow: none;min-width: 5rem;"
                        @click="hideConfirm"
                    >cancel</button>
                    <button
                        type="button"
                        class="btn btn-new-primary ml-4 px-4"
                        style="box-shadow: none;min-width: 5rem;"
                        @click="deleteConfirm"
                    >Delete Business Unit</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
export default {
    props: ["modal_name"],
    methods: {
        hideConfirm() {
            this.$modal.hide(this.modal_name);
            this.$emit('hideConfirm');
        },
        deleteConfirm() {
            this.$modal.hide(this.modal_name);
            this.$emit('deleteConfirm');
        }
    }
}
</script>
<style>
    .btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
        width: 120px;
        border-radius: 5px;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
</style>